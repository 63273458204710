import moment from "moment"

export const doctorTextMessageTemplate = () => {
  return `[${moment().format(
    "YYYY/MM/DD hh:mm:ss A"
  )}] Welcome to the TaskUs Wellness Program. Your enrollment has been APPROVED. Check your email for details on how to get started. For inquiries, you may contact us at 0917 866 9487. Thank you.`
}

export const patientTextMessageTemplate = () => {
  return `[${moment().format(
    "YYYY/MM/DD hh:mm:ss A"
  )}] Thank you for enrolling in the TaskUs Wellness Program. We are processing your enrollment. Our pharmacist will update you during business hours (Mon - Fri, 8:30AM-4:30PM). If you do not receive an SMS, please contact us at 0917 866 9487. Thank you.`
}
