const hasNoEmptyValue = (object) => {
  let emptyValues = Object.values(object).filter((x) => {
    switch (typeof x) {
      case "object":
        if (!x?.value || x?.value === "" || x?.value === null) return true
        else return false
      case "string":
      default:
        if (!x || x === "" || x === null) return true
        else return false
    }
  })
  return emptyValues
}

export const hasIncompleteValues = ({ data, fields }) => {
  let requiredFields = fields?.filter((field) => field.required === true)

  for (let i = 0; i < requiredFields.length; i++) {
    let fieldName = requiredFields[i]?.name || ""

    let keys
    let finalValue

    switch (fieldName) {
      case "address":
        finalValue = hasNoEmptyValue(data[fieldName])
        if (finalValue.length > 0) return true
        else return false
      default:
        keys = fieldName?.split(".")
        finalValue = keys?.reduce((object, key) => object[key], data)
        if (!finalValue) return true
        else return false
    }
  }
}
