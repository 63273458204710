const printDateValue = (date) => {
  return `${date?.month?.label} ${
    date?.date?.label ? `${date?.date?.label}, ` : ""
  }${date?.year ? `${date?.year}` : ""}`
}

export const patientEnrollmentTicketBody = (request) => {
  let { emergencyContact } = request
  let {
    firstName,
    lastName,
    birthday,
    gender,
    mobileNumber,
    address,
    email,
    workEmail,
    personalEmail,
    employeeNumber,
    site,
    department,
    startDate,
    emergencyContactName,
    emergencyContactNumber,
    emergencyContactRelationship,
  } = request

  return `-Personal Details-
  First Name: ${firstName}
  Last Name: ${lastName}
  Birthdate: ${printDateValue(birthday)}
  Mobile Number: ${mobileNumber}
  Gender: ${gender}
  
  -${address?.addressType} Address-
  Street Address: ${address?.streetAddress}
  City: ${address?.city.value}
  Province: ${address?.province.value}

  -Employee Information-
  Work Email: ${workEmail || email}
  Personal Email: ${personalEmail || email}
  Employee Number: ${employeeNumber}
  Site: ${site?.label || "N/A"}
  Department: ${department}
  Start Date: ${printDateValue(startDate)}

  -Emergency Contact-
  Name: ${emergencyContactName || "N/A"}
  Mobile Number: ${emergencyContactNumber || "N/A"}
  Relationship to Emergency Contact: ${
    emergencyContactRelationship.label || "N/A"
  }
  `
}
