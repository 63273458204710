import { sendEmail } from "../../../services/sendEmail"
import {
  sendToZendesk,
  zendeskUploadFiles,
  generateUploadToken,
  generateUploadUrl,
} from "../../../services/zendeskService"
import { enrollmentValidationSchema } from "../utils/enrollmentValidationSchema"
import firebase from "firebase"

import { getSignedInUser } from "../../Auth/services/user"
import { checkIfUserAlreadyExists } from "../../Auth/services/signup"
import {
  GATSBY_WEBSITE_URL,
  GATSBY_FIREBASE_PROJECT_ID,
  GATSBY_FIREBASE_PATIENT_ROLE_ID,
  GATSBY_AWS_SES_EMAIL_TO,
  GATSBY_AWS_SES_EMAIL_FROM,
  GATSBY_AWS_SES_EMAIL_BCC,
  GATSBY_ENV,
} from "gatsby-env-variables"
import { getUserAddresses, getUserData } from "../../Auth/services/signin"
import { checkIfAddressIsUnique } from "../utils/checkIfAddressIsUnique"

export const shouldPatientButtonBeDisabled = ({ formData, isPersonal }) => {
  if (formData?.agreeToConsent?.length < 1) return true
  else {
    let isFormValid
    isFormValid = enrollmentValidationSchema(isPersonal)
      .isValid({
        ...formData,
      })
      .then((valid) => {
        isFormValid = valid
        return isFormValid
      })

    return !isFormValid
  }
}

const formatName = (firstName, lastName, middleInitial) => {
  const MI = middleInitial ? ` ${middleInitial} ` : " "
  return `${firstName}${MI}${lastName}`
}

const capitalize = (string) => {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
}

export const sendEnrollmentForm = async (config) => {
  let { errorCallback, callback, enrollmentData, ids } = config

  // Zendesk image
  try {
    if (ids?.length > 0) {
      let uploadedFiles = await zendeskUploadFiles(ids)
      let generatedFileTokens = await generateUploadToken(uploadedFiles)
      let generatedFileUrls = await generateUploadUrl(uploadedFiles)

      enrollmentData.generatedFileTokens = await generatedFileTokens
      enrollmentData.generatedFileUrls = await generatedFileUrls
    }
  } catch (error) {
    if (errorCallback) errorCallback()
    return
  }

  try {
    let { authUser, addresses, userData } = getSignedInUser()

    //add Uploads
    try {
      if (ids?.length > 0) {
        let uploadedFiles = await zendeskUploadFiles(ids)
        let generatedFileTokens = await generateUploadToken(uploadedFiles)
        enrollmentData.generatedFileTokens = await generatedFileTokens
      }
    } catch (error) {
      if (errorCallback) errorCallback()
      return
    }

    let email = authUser?.email
    let authUid = authUser?.uid
    let addressesId = addresses?.id || ""
    let addressesList = addresses?.addresses || []
    let userRoles =
      userData?.roles?.filter(
        (role) => role.subdomain !== GATSBY_WEBSITE_URL
      ) || []
    let userAllowedSubdomains =
      userData?.allowedSubdomains?.filter(
        (subdomain) => subdomain !== GATSBY_WEBSITE_URL
      ) || []

    if (!authUid) {
      userData = await checkIfUserAlreadyExists(authUser.email)
      authUid = userData?.authUid
    }

    let emergencyContact = {
      name: enrollmentData.emergencyContactName,
      mobileNumber: enrollmentData.emergencyContactNumber,
      relationship: enrollmentData.emergencyContactRelationship,
    }

    // Add  address details
    let addressInformation = {
      primary: true,
      type: enrollmentData?.address?.addressType?.toLowerCase(),
      streetAddress: enrollmentData?.address?.streetAddress,
      city: enrollmentData?.address?.city?.value,
      province: enrollmentData?.address?.province?.value,
    }

    // Check if address exists
    let isAddressUnique = checkIfAddressIsUnique(
      addressInformation,
      addressesList
    )

    let finalAddressList = []
    if (isAddressUnique) {
      // Or remove primary from others, use new address as primary (since most recent enrollment --> most recent address)
      if (addressesList?.length > 0) {
        addressesList.forEach((address) => {
          address.primary = false
        })
      }
      finalAddressList = [...addressesList, { ...addressInformation }]
    } else {
      finalAddressList = [...addressesList]
    }

    let address = await firebase
      .firestore()
      .collection("addresses")
      .add({ addresses: [...finalAddressList] })
    addressesId = address.id

    let { month, date, year } = enrollmentData?.birthday

    const USER_DOCUMENT = {
      firstName: enrollmentData?.firstName || "",
      lastName: enrollmentData?.lastName || "",
      mobileNumber: enrollmentData?.mobileNumber || "",
      email: email || "",
      workEmail: enrollmentData?.workEmail || "",
      personalEmail: enrollmentData?.personalEmail || "",
      addresses: addressesId,
      birthday: {
        month: month,
        date: date,
        year: year,
      },
      gender: enrollmentData?.gender,
      emergencyContact: { ...emergencyContact },
      employeeNumber: enrollmentData?.employeeNumber,
      department: enrollmentData?.department,
      site: enrollmentData?.site,
      startDate: enrollmentData?.startDate,
      roles: [
        ...userRoles,
        {
          status: "active",
          subdomain: GATSBY_WEBSITE_URL,
          role: GATSBY_FIREBASE_PATIENT_ROLE_ID,
          projectId: GATSBY_FIREBASE_PROJECT_ID,
        },
      ],
      allowedSubdomains: [...userAllowedSubdomains, GATSBY_WEBSITE_URL],
      companyId: enrollmentData.generatedFileTokens || [],
    }

    // User data will always be null
    if (userData?.id) {
      await firebase
        .firestore()
        .collection("users")
        .doc(userData?.id)
        .update({ ...USER_DOCUMENT, authUid })
    } else {
      await firebase
        .firestore()
        .collection("users")
        .add({ ...USER_DOCUMENT, authUid })
    }

    // Add email to enrollmentData object
    enrollmentData.email = email

    await sendEnrollmentFormToZendesk({
      config,
      document: { ...USER_DOCUMENT, authUid },
    })

    let newUserData = await getUserData({ authUid })
    let userAddresses = await getUserAddresses({ addressesId })

    if (callback) callback(newUserData, userAddresses)
  } catch (error) {
    if (errorCallback) errorCallback()
  }
}

export const sendEnrollmentFormToEmail = (config) => {
  let { enrollmentData, emailTemplate, type } = config
  const { firstName, lastName, middleInitial, email } = enrollmentData
  const fullname = formatName(firstName, lastName, middleInitial)

  const subject = `TaskUs Wellness Program: ${capitalize(
    type
  )} Enrollment for ${fullname} has been ${
    type === "doctor" ? "Approved" : "Received"
  }`
  return sendEmail({
    to: GATSBY_AWS_SES_EMAIL_TO,
    cc: email,
    from: GATSBY_AWS_SES_EMAIL_FROM,
    subject,
    html: emailTemplate(enrollmentData),
    bcc: GATSBY_AWS_SES_EMAIL_BCC,
  })
}

export const sendEnrollmentFormToZendesk = async ({ config, document }) => {
  let { enrollmentData, ticketTemplate, type } = config
  let isTest = GATSBY_ENV !== "production"

  let tags = [`${type}_enrollment`, "taskus_enrollment", "taskus_mh"]
  if (isTest) tags.push("test")

  const { firstName, lastName, middleInitial, email } = enrollmentData
  const fullname = formatName(firstName, lastName, middleInitial)

  let subject = `${isTest ? "[TEST] " : ""}TaskUs Wellness: ${capitalize(
    type
  )} Enrollment Form of ${fullname}`

  let customFields = []
  const ZENDESK_JSON_FIELD_ID = 900008616066

  customFields.push({
    id: ZENDESK_JSON_FIELD_ID,
    value: JSON.stringify(document),
  })

  let requestBody = {
    type: "request",
    tags,
    subject,
    requester: {
      name: fullname,
      email: email,
    },
    email_ccs: [
      { user_email: enrollmentData?.personalEmail || enrollmentData?.email },
    ],
    comment: { body: ticketTemplate(enrollmentData) },
    custom_fields: customFields,
  }

  if (enrollmentData?.generatedFileTokens?.length > 0) {
    requestBody.comment.uploads = [...enrollmentData.generatedFileTokens]
    tags.push("taskus_unverified")
  }

  return await sendToZendesk(requestBody, [])
}
