import React, { useContext, useState, Fragment } from "react"
import { navigate } from "gatsby"
import classNames from "classnames"
import styles from "./utils/enrollmentSummary.module.scss"

import Layout from "layout/Layout"
import Container from "layout/Container"
import Section from "elements/Section"
import Message from "elements/Message"
import FileThumbnail from "elements/Dropzone/FileThumbnail"
import EditDetailsButton from "elements/EditDetailsButton"
import { isBrowser } from "services/general"
import { isPersonalEmail } from "../utils/isPersonalEmail"
import EnrollmentConsent from "./EnrollmentConsent"

import { useMentalHealthFormFields } from "../hooks/useAirtableMHFormFields"
import { sendEnrollmentForm } from "../services/enrollment"
import { AppContext } from "../../../context/AppContext"

import patientEnrollmentTemplate from "../utils/templates/patientEnrollmentEmailTemplate"
import { patientEnrollmentTicketBody } from "../utils/templates/patientEnrollmentZendeskTemplate"
import { patientTextMessageTemplate } from "../utils/templates/enrollmentTextMessageTemplate"
import { get } from "lodash"
import { hasIncompleteValues } from "../services/form"

let { parseFormField } = require("services/airtable")

const EnrollmentSummary = (props) => {
  const [loading, setLoading] = useState(false)
  const [hasErrorSubmitting, setHasErrorSubmitting] = useState(false)
  const { state, dispatch } = useContext(AppContext)

  let { pageContext } = props
  let { module, backPath, nextPath } = pageContext

  const { enrollment, documents } = state
  let { sectionFormFields, validationSchema } = useMentalHealthFormFields({
    formFields: pageContext.formFields,
  })

  let sectionSummaryFields = parseFormField(
    pageContext.summaryFields.map((formField) => ({
      ...formField,
      section: formField.summarySection,
    }))
  )
  sectionSummaryFields = sectionSummaryFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )

  const ids = documents?.filter((document) => document.name.startsWith("id"))

  const handleSuccessSubmit = async (userData, userAddresses) => {
    setLoading(false)
    await dispatch({ type: "RESET_DETAILS" })
    await dispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })

    if (isBrowser()) {
      sessionStorage.setItem("userData", JSON.stringify({ ...userData }))
      sessionStorage.setItem("addresses", JSON.stringify(userAddresses))
    }
    navigate(nextPath)
  }

  const handleErrorSubmit = () => {
    setLoading(false)
    setHasErrorSubmitting(true)
  }

  const handleSubmit = async () => {
    setLoading(true)
    setHasErrorSubmitting(false)

    await sendEnrollmentForm({
      ids: ids,
      enrollmentData: enrollment,
      emailTemplate: patientEnrollmentTemplate,
      ticketTemplate: patientEnrollmentTicketBody,
      type: "client",
      callback: handleSuccessSubmit,
      errorCallback: handleErrorSubmit,
      textMessageTemplate: patientTextMessageTemplate,
      module: module.name,
    })
  }

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.title}
      pageContext={pageContext}
      verifyPage
      display={{ footer: false, helpCenterBanner: false }}
    >
      <Container isCentered>
        <Fragment>
          {sectionSummaryFields.map((section) => {
            const route = section.link
            // const route = `${section.link}${
            //   section.sectionId ? `#${section.sectionId}` : ""
            // }`
            return (
              <Section
                title={section?.section}
                addOns={{
                  right: <EditDetailsButton route={route} />,
                }}
              >
                {!hasIncompleteValues({
                  data: enrollment,
                  fields: section.fields,
                }) ? (
                  <div className="table-container">
                    <table class="table is-fullwidth is-size-5">
                      <tbody>
                        {section.fields
                          .sort((firstField, secondField) => {
                            return firstField.order - secondField.order
                          })
                          .map((field) => {
                            let finalValue = null
                            switch (field.type) {
                              case "date":
                                finalValue = get(enrollment, field.name)
                                  ? `${
                                      get(enrollment, field.name)?.month?.label
                                    } ${
                                      get(enrollment, field.name)?.date?.label
                                        ? `${
                                            get(enrollment, field.name)?.date
                                              ?.label
                                          }, `
                                        : ""
                                    }${get(enrollment, field.name)?.year}`
                                  : ""
                                break
                              case "address":
                                finalValue = get(enrollment, field.name)
                                  ? `${
                                      get(enrollment, field.name)?.streetAddress
                                    }, ${
                                      get(enrollment, field.name)?.city?.label
                                    }, ${
                                      get(enrollment, field.name)?.province
                                        ?.label
                                    }`
                                  : ""
                                break
                              case "select":
                                finalValue = get(enrollment, field.name)
                                  ? get(enrollment, field.name)?.label
                                  : ""
                                break
                              default:
                                finalValue = get(enrollment, field.name)
                                break
                            }

                            if (!!finalValue)
                              return (
                                <tr>
                                  <td>{field.summaryLabel}</td>
                                  <td
                                    className={classNames(
                                      "has-text-weight-bold",
                                      styles["summary__tableData"]
                                    )}
                                  >
                                    {finalValue}
                                  </td>
                                </tr>
                              )
                            return null
                          })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <Message color="warning">
                    Some required information is missing or incomplete. Please
                    double check if you have filled out the required fields.
                  </Message>
                )}
              </Section>
            )
          })}
          {isPersonalEmail() && (
            <Section
              title="Company ID"
              addOns={{
                right: <EditDetailsButton route="/enrollment#company-id" />,
              }}
            >
              {ids?.length > 0 ? (
                <div className="mx-1">
                  <div className="columns is-vcentered is-multiline">
                    {ids?.map((id) => (
                      <div className="column is-6">
                        <FileThumbnail file={id} hasFileName />
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </Section>
          )}
          <EnrollmentConsent
            sectionFormFields={sectionFormFields}
            validationSchema={validationSchema}
            handleSubmit={handleSubmit}
            pageContext={pageContext}
            loading={loading}
          />
        </Fragment>
      </Container>
    </Layout>
  )
}

export default EnrollmentSummary
